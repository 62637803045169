<nav
  class="sidebar-menu"
  [class.light-theme]="layoutType() === 'internal'"
  role="navigation"
  aria-label="Main Navigation">
  <div class="sidenav__logo" aria-hidden="true">
    <a routerLink="/dashboard">
      <img
        [ngSrc]="
          layoutType() === 'internal'
            ? '/img/logo-blue.png'
            : '/img/logo-white-small.png'
        "
        alt="Logo Supra"
        height="35"
        width="118"
        priority />
    </a>
  </div>
  <ul class="sidebar-menu__list sidebar-menu__list--full-height">
    @for (menuOption of menuOptions(); track $index) {
      @if (
        !menuOption.permission ||
        (menuOption.permission &&
          userStore.hasPermission(menuOption.permission))
      ) {
        <li class="sidebar-menu__item">
          <a
            [routerLink]="menuOption.route"
            routerLinkActive="sidebar-menu__item--active"
            [attr.aria-label]="menuOption.label | translate"
            (click)="handleCloseMenu()"
            (keydown.enter)="handleCloseMenu()"
            [tabindex]="$index">
            <mat-icon>{{ menuOption.icon }}</mat-icon>
            <span>{{ menuOption.label | translate }}</span>
          </a>
        </li>
      }
    }
  </ul>
  <ul class="sidebar-menu__list">
    <li class="sidebar-menu__item">
      <a
        [routerLink]="layoutType() === 'internal' ? '/internal/user' : '/user'"
        routerLinkActive="sidebar-menu__item--active"
        [attr.aria-label]="'sidebar.menu.profile' | translate"
        (click)="handleCloseMenu()"
        (keydown.enter)="handleCloseMenu()"
        [tabindex]="menuOptions().length">
        <mat-icon>person</mat-icon>
        <span>{{ 'sidebar.menu.profile' | translate }}</span>
      </a>
    </li>
    <li class="sidebar-menu__item sidebar-menu__logout">
      <button
        [attr.aria-label]="'sidebar.menu.signOut' | translate"
        (click)="signOut()"
        [tabindex]="menuOptions().length + 1">
        <mat-icon>logout</mat-icon>
        <span>{{ 'sidebar.menu.signOut' | translate }}</span>
      </button>
    </li>
  </ul>
</nav>
