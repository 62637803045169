import { Component } from '@angular/core';
import {
  MatSidenavContainer,
  MatSidenavContent,
} from '@angular/material/sidenav';
import { MatToolbar } from '@angular/material/toolbar';
import { RouterOutlet } from '@angular/router';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-toolbar-layout',
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenavContent,
    MatToolbar,
    RouterOutlet,
    NgOptimizedImage,
  ],
  templateUrl: './toolbar-layout.component.html',
  styleUrl: './toolbar-layout.component.scss',
})
export class ToolbarLayoutComponent {}
