import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../shared/components/dump-components/alert-dialog/alert-dialog.component';
import { CacheService } from '../../shared/services/cache.service';
import { UserStore } from '../store/user-store';
import { AuthService } from './auth.service';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private errorService = inject(GlobalErrorHandlerService);
  authService = inject(AuthService);
  userStore = inject(UserStore);
  cacheService = inject(CacheService);
  dialog = inject(MatDialog);

  private tenMinutes = environment.sessionTimeout;

  async checkSessionExpiration(): Promise<boolean> {
    try {
      const lastRequestDate = localStorage.getItem('lastRequestDate');

      if (lastRequestDate) {
        const lastRequestTime = new Date(lastRequestDate).getTime();
        const currentTime = new Date().getTime();
        if (currentTime - lastRequestTime >= this.tenMinutes) {
          localStorage.removeItem('lastRequestDate');
          this.dialog.closeAll();
          await this.authService.signOut();
          await this.userStore.resetUserData();
          await this.cacheService.clearAppCache(true);
          this.dialog.open(AlertDialogComponent, {
            data: {
              message: 'auth.expirationSession.message',
              title: 'auth.expirationSession.title',
              actionLabel: 'auth.expirationSession.actionLabel',
            },
            width: 'auto',
          });
          return true;
        }
      }
      return false;
    } catch (error) {
      this.errorService.logError(error);
      return false;
    }
  }
}
