import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {UserStore} from "../store/user-store";

export const MainGuard: CanActivateFn = async (route, state) => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  await userStore.loadPermissions();
  if(userStore.roles().includes('applicant') && state.url !== '/dashboard/new-user-landing'){
    await router.navigate(['/dashboard/new-user-landing']);
    return false;
  }
  return true;
};
