/* src/app/layouts/components/smart-components/auth-layout/auth-layout.component.scss */
.auth-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  box-sizing: border-box;
}
.auth-layout__left-section {
  width: 100%;
  max-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;
}
.auth-layout__promo {
  display: none;
}
@media (min-width: 768px) {
  .auth-layout {
    flex-direction: row;
  }
  .auth-layout__promo {
    display: block;
    width: 50%;
    height: 100%;
  }
  .auth-layout__left-section {
    width: 50%;
  }
}
/*# sourceMappingURL=auth-layout.component.css.map */
