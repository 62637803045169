/* src/app/layouts/components/dump-components/promo-section/promo-section.component.scss */
.promo {
  background: #00022D;
  color: #ffffff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.promo__image img {
  width: 100%;
  max-width: 500px;
  height: auto;
}
/*# sourceMappingURL=promo-section.component.css.map */
