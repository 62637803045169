import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, EMPTY, from, mergeMap, take } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SessionTimeoutService } from '../services/session-timeout.service';

const sessionExpired$ = new BehaviorSubject<boolean>(false);
let isCheckingSession = false;

let sessionCheckPromise: Promise<void> | null = null;

export const sessionTimeoutInterceptor: HttpInterceptorFn = (req, next) => {
  const supraApi = environment.supraApi;
  const isSupraApiRequest = req.url.includes(supraApi);

  if (!isSupraApiRequest) {
    return next(req);
  }

  const sessionTimeoutService = inject(SessionTimeoutService);
  return sessionExpired$.pipe(
    take(1),
    mergeMap(isSessionExpiredGlobally => {
      if (isSessionExpiredGlobally) {
        sessionExpired$.next(false);
        return next(req);
      }

      if (isCheckingSession && sessionCheckPromise) {
        return from(sessionCheckPromise).pipe(
          mergeMap(() => (sessionExpired$.value ? EMPTY : next(req)))
        );
      }

      isCheckingSession = true;
      sessionCheckPromise = sessionTimeoutService
        .checkSessionExpiration()
        .then(isSessionExpired => {
          if (isSessionExpired) {
            sessionExpired$.next(true);
          } else {
            const currentDate = new Date().toISOString();
            localStorage.setItem('lastRequestDate', currentDate);
          }
        })
        .finally(() => {
          isCheckingSession = false;
          sessionCheckPromise = null;
        });

      return from(sessionCheckPromise).pipe(
        mergeMap(() => (sessionExpired$.value ? EMPTY : next(req)))
      );
    })
  );
};
