import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, from, mergeMap, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CacheService } from '../../shared/services/cache.service';
import { SnackbarService } from '../../shared/services/snackbar-service.service';
import { AuthService } from '../services/auth.service';
import { UserStore } from '../store/user-store';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const supraApi = environment.supraApi;
  const isSupraApiRequest = req.url.includes(supraApi);

  if (!isSupraApiRequest) {
    return next(req);
  }

  const authService = inject(AuthService);
  const snackbarService = inject(SnackbarService);
  const userStore = inject(UserStore);
  const cacheService = inject(CacheService);
  return from(authService.getAuthToken()).pipe(
    switchMap(authToken => {
      const tokenExists = !!authToken;
      const newReq = tokenExists
        ? req.clone({
            headers: req.headers
              .set('Authorization', `Bearer ${authToken}`)
              .set('X-API-TYPE', 'public')
              .set('x-source', 'supra-app'),
          })
        : req;

      return next(newReq);
    }),
    catchError(error => {
      if (error.error.statusCode === 401) {
        return from(
          (async () => {
            await Promise.all([
              userStore.resetUserData(),
              authService.signOut(),
              cacheService.clearAppCache(true),
              snackbarService.error('auth.notAuthorizedSession.unauthorized'),
            ]);
          })()
        ).pipe(mergeMap(() => next(req)));
      }
      return next(req);
    })
  );
};
