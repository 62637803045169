<mat-sidenav-container class="toolbar-layout">
  <mat-sidenav-content>
    <mat-toolbar class="toolbar-layout__toolbar">
      <img ngSrc="img/logo-blue.png" alt="Logo Supra" height="45" width="156" />
    </mat-toolbar>
    <div class="toolbar-layout__content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
