/* src/app/layouts/components/smart-components/sidebar-menu/sidebar-menu.component.scss */
.sidenav__logo {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
.sidenav__logo img {
  width: 93px;
  height: 27px;
}
.light-theme .sidebar-menu__item {
  color: #00022D;
}
.light-theme .sidebar-menu__item--active {
  background-color: #f3f4f6;
  color: #00022D;
}
.light-theme .sidebar-menu__item a.active,
.light-theme .sidebar-menu__item a:focus {
  background-color: #f3f4f6;
  color: #00022D;
}
.light-theme .sidebar-menu__item a:hover {
  background-color: #f3f4f6;
  color: #111827;
}
.light-theme .sidebar-menu__logout button {
  color: #00022D;
}
.sidebar-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 50px 0;
}
.sidebar-menu__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar-menu__list--full-height {
  height: 100%;
}
.sidebar-menu__logout button {
  color: #ffffff;
  cursor: pointer;
  padding: 20px 20px;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
  align-items: center;
  justify-content: left;
  transition: background-color 0.3s ease;
  text-align: center;
  font-size: 16px;
}
.sidebar-menu__item {
  display: flex;
  justify-content: center;
  color: #ffffff;
}
.sidebar-menu__item a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 20px;
  width: 100%;
  transition: background-color 0.3s ease;
  text-align: center;
}
.sidebar-menu__item--active {
  background-color: #13153A;
  color: #32ef76;
}
.sidebar-menu__item a.active,
.sidebar-menu__item a:focus {
  background-color: #13153A;
  color: #32ef76;
}
.sidebar-menu__item a:hover {
  background-color: #13153A;
}
.sidebar-menu__item mat-icon {
  font-size: 24px;
}
.sidebar-menu__item a.active mat-icon,
.sidebar-menu__item a.active span {
  color: #ffffff;
}
/*# sourceMappingURL=sidebar-menu.component.css.map */
