import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, computed, inject, input, output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../../../../core/services/auth.service';

import { toSignal } from '@angular/core/rxjs-interop';
import { SalesStore } from '../../../../core/store/sales-store';
import { UserStore } from '../../../../core/store/user-store';
import { CacheService } from '../../../../shared/services/cache.service';
import { DeviceDetectorService } from '../../../../shared/services/device-detector.service';

interface MenuOption {
  label: string;
  route: string;
  icon: string;
  permission?: string;
  avoidMobile?: boolean;
}

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MatIcon,
    RouterLinkActive,
    TranslateModule,
    NgOptimizedImage,
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss',
})
export class SidebarMenuComponent {
  private deviceDetectorService = inject(DeviceDetectorService);
  isMobile = toSignal(this.deviceDetectorService.isMobile$);
  closeMenu = output();
  authService = inject(AuthService);
  userStore = inject(UserStore);
  salesStore = inject(SalesStore);
  cacheService = inject(CacheService);
  layoutType = input<'internal' | null>(null);
  menuOptions = computed<MenuOption[]>(() => {
    const layoutOptions: MenuOption[] = {
      external: [
        {
          label: 'sidebar.menu.home',
          route: '/dashboard',
          icon: 'home',
        },
        {
          label: 'sidebar.menu.recipients',
          route: '/recipient',
          icon: 'group',
          permission: 'beneficiary-management',
        },
        {
          label: 'sidebar.menu.transfers',
          route: '/payout',
          icon: 'sync_alt',
          permission: 'transfer-preparation',
        },
        {
          label: 'sidebar.menu.internationalCollection',
          route: '/international-collection',
          icon: 'account_balance_wallet',
          permission: 'collection-management',
        },
        {
          label: 'sidebar.menu.userManagement',
          route: '/admin/users',
          icon: 'groups',
          permission: 'user-management',
          avoidMobile: true,
        },
        {
          label: 'sidebar.menu.backoffice',
          route: '/internal',
          icon: 'settings',
          permission: 'back-office-access',
        },
      ],
      internal: [
        {
          label: 'sidebar.menu.operationInvoices',
          route: '/internal/ops/invoices',
          icon: 'payments',
          permission: 'get-invoices',
        },
        {
          label: 'sidebar.menu.operationConversions',
          route: '/internal/ops/exchanges',
          icon: 'currency_exchange',
          permission: 'list-all-currency-conversions',
        },
        {
          label: 'sidebar.menu.sales',
          route: '/internal/sales/sales-team',
          icon: 'groups_2',
          permission: 'get-sales-team',
          avoidMobile: true,
        },
        {
          label: 'sidebar.menu.salesCompanies',
          route: '/internal/sales/companies',
          icon: 'business',
          permission: 'list-approves-companies',
        },
        {
          label: 'sidebar.menu.salesPortfolio',
          route: '/internal/sales/portfolio',
          icon: 'business_center',
          permission: 'list-sales-companies',
          avoidMobile: true,
        },
      ],
    }[this.layoutType() ?? 'external'];

    return this.isMobile()
      ? layoutOptions.filter(option => !option?.avoidMobile)
      : layoutOptions;
  });

  handleCloseMenu() {
    this.closeMenu.emit();
  }

  async signOut() {
    await this.authService.signOut();
    await this.userStore.resetUserData();
    await this.cacheService.clearAppCache();
    await this.salesStore.resetUserData();
  }
}
