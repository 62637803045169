/* src/app/layouts/components/smart-components/toolbar-layout/toolbar-layout.component.scss */
.toolbar-layout {
  overflow: auto;
  height: 100%;
}
.toolbar-layout__toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 30px;
  background-color: #ffffff;
  border-bottom: 1px solid #929EAA;
}
.toolbar-layout__toolbar img {
  width: 83px;
  height: 24px;
}
.toolbar-layout__content {
  margin-top: 64px;
  padding: 16px;
  box-sizing: border-box;
}
/*# sourceMappingURL=toolbar-layout.component.css.map */
