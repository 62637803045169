/* src/app/layouts/components/smart-components/main-layout/main-layout.component.scss */
.sidenav-container {
  overflow: auto;
  height: 100%;
}
.light-theme .sidenav {
  background-color: #ffffff;
}
.sidenav {
  width: 156px;
  background-color: #00022D;
}
.toolbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.mat-sidenav-content {
  overflow-y: auto;
  box-sizing: border-box;
  height: 100%;
}
@media (min-width: 600px) {
  .mat-sidenav-content {
    margin: 0;
  }
}
.main-content {
  margin-top: 56px;
  padding: 16px;
  box-sizing: border-box;
}
@media (min-width: 600px) {
  .main-content {
    min-height: 100%;
    margin: 0;
  }
}
/*# sourceMappingURL=main-layout.component.css.map */
